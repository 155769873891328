@font-face {
  font-family: 'Avenir-Light';
  src: url('./assets/fonts/Avenir-Light.woff2') format('woff2'),
    url('./assets/fonts/Avenir-Light.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Book';
  src: url('./assets/fonts/Avenir-Book.woff2') format('woff2'),
    url('./assets/fonts/Avenir-Book.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('./assets/fonts/Avenir-Medium.woff2') format('woff2'),
    url('./assets/fonts/Avenir-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: url('./assets/fonts/Avenir-Heavy.woff2') format('woff2'),
    url('./assets/fonts/Avenir-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'astra';
  src: url('./assets/fonts/astra.woff2?g34lcq') format('woff2'),
    url('./assets/fonts/astra.ttf?g34lcq') format('truetype'),
    url('./assets/fonts/astra.woff?g34lcq') format('woff'),
    url('./assets/fonts/astra.svg?g34lcq#astra') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  box-sizing: inherit;
  outline: 0;
  padding: 0;
  margin: 0;
}

html {
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Avenir-Book', sans-serif;
}

html,
body,
#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Avenir-Heavy', sans-serif;
}

h1 {
  font-size: 2em;
  line-height: 1.2em;
}

h2 {
  font-size: 1.8em;
  line-height: 1.2em;
}

h3 {
  font-size: 1.6em;
  line-height: 1.2em;
}

h4 {
  font-size: 1.4em;
  line-height: 1.2em;
}

h5 {
  font-size: 1.2em;
  line-height: 1.2em;
}

p {
  font-size: 1em;
  line-height: 1.2em;
}

/* Icons */
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'astra' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-routine-balance-notification:before {
  content: '\e911';
}
.icon-percentage-balance:before {
  content: '\e91d';
}
.icon-routine-deposit-notification:before {
  content: '\e91c';
}
.icon-refill:before {
  content: '\e91b';
}
.icon-routine-fifty-two-week:before {
  content: '\e902';
}
.icon-routine-recurring:before {
  content: '\e904';
}
.icon-routine-threshold-notification:before {
  content: '\e908';
}
.icon-routine-percentage:before {
  content: '\e90a';
}
.icon-routine-round-up:before {
  content: '\e910';
}
.icon-routine-one-time:before {
  content: '\e912';
}
.icon-routine-sweep:before {
  content: '\e913';
}
.icon-bank:before {
  content: '\e917';
}
